import React from 'react'
import PropTypes from 'prop-types'

const Footer = ({ copyrights }) => (
  <footer>
    <p>
      Other places to find Dave: &nbsp;
      <a href="https://www.linkedin.com/in/davidgustav">LinkedIn</a> &nbsp;
      <a href="https://github.com/davidgustav">GitHub</a> &nbsp;
      <a href="https://www.instagram.com/davidgustav/">Instagram</a>
    </p>
    {copyrights ? (
      <div
        dangerouslySetInnerHTML={{
          __html: copyrights,
        }}
      />
    ) : (
      <>
        <span className="footerCopyrights">
          © 2019 Built with <a href="https://www.gatsbyjs.org">Gatsby</a>
        </span>
        <span className="footerCopyrights">
          Starter created by <a href="https://radoslawkoziel.pl">panr</a>
        </span>
      </>
    )}
  </footer>
)

Footer.propTypes = {
  copyrights: PropTypes.string,
}

export default Footer
